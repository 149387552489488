body {
  font-family: CalibriWeb, Arial, sans-serif;
  background: rgb(161, 161, 161);
}

button {
  font-family: inherit;
}

a {
  color: currentColor;
  text-decoration: none;
}
